var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDialogVisible)?_c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"headline text-uppercase primary--text"},[_vm._v("Ajustes de Inmueble")]),_c('v-btn',{attrs:{"x-small":"","color":"error","fab":"","outlined":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"background-color":"primary","fixed-tabs":""},on:{"change":_vm.handleTab},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#email"}},[_vm._v(" Correo "),_c('v-icon',[_vm._v("mdi-account-box")])],1),_c('v-tab',{attrs:{"href":"#sms"}},[_vm._v(" SMS "),_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-tab',{attrs:{"href":"#call"}},[_vm._v(" Llamada "),_c('v-icon',[_vm._v("mdi-phone")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab-item',{key:item,attrs:{"value":item}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"disabled":_vm.isLoading,"label":"Habilitado"},on:{"change":_vm.handleCheckbox},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-col',{staticClass:"mt-n7",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","rounded":""}},[_c('v-card-text',{staticClass:"my-n2 mb-n3"},[_c('div',{staticClass:"d-flex justify-end mr-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"x-small":"","disabled":_vm.isLoading || _vm.isComplete,"color":"success","fab":"","outlined":"","height":"25","width":"25"},on:{"click":function () { return _vm.openModalSettingForm(_vm.tab); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Agregar "+_vm._s(_vm.tab === 'email' ? 'Correo' : _vm.tab === 'sms' ? 'SMS' : 'Llamada')+" ")])])],1)]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-n2"},[(!_vm.isLoading)?[(_vm.settingsValues.length > 0)?_c('v-simple-table',{attrs:{"height":"265","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left primary--text"},[_vm._v("Correo")]),_c('th',{staticClass:"text-left primary--text"},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.settingsValues),function(data){return _c('tr',{key:data._id},[_c('td',[_vm._v(_vm._s(data.value))]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"color":"warning","fab":"","outlined":"","x-small":"","height":"25","width":"25"},on:{"click":function () { return _vm.openModalSettingForm(_vm.tab, data); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","fab":"","outlined":"","x-small":"","height":"25","width":"25"},on:{"click":function () { return _vm.openModalSettingConfirmation(
                                                      _vm.tab,
                                                      true,
                                                      false,
                                                      data._id
                                                    ); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)]},proxy:true}],null,true)}):_vm._e(),(_vm.settingsValues.length === 0)?_c('custom-message',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"40vh"},attrs:{"message":("No dispone de " + (_vm.tab === 'call' ? 'ninguna' : 'ningun') + " " + (_vm.tab === 'email' ? 'Correo' : _vm.tab === 'sms' ? 'SMS' : 'Llamada') + " en este momento")}}):_vm._e()]:_vm._e(),(_vm.isLoading)?_c('custom-progress-circular',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"40vh"}}):_vm._e()],2)],1)],1)],1)],1)],1)}),1)],1)],1)],1),_c('v-divider')],1)],1),_c('modal-setting-form',{ref:"modalSettingForm",on:{"successfully":function (type) { return _vm.loadSettings(type); }}}),_c('modal-setting-confirmation',{ref:"modalSettingConfirmation",on:{"successfully":function (type) { return _vm.loadSettings(type); },"cancel-submit":_vm.onCancel}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }