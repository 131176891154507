import { defineComponent, ref, watch } from '@vue/composition-api';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import useToastification from '@/app/common/hooks/useToastification';
import { ErrorModel } from '@/app/models/error';
import { RefFormModel } from '@/app/models/reference';
import { SpaceModel } from '@/app/models/space';
import { required } from '@/app/common/validator';
import { SelectModel } from '@/app/models/select-model';
import { CompaniesPagingParams } from '@/app/models/paginate';
import companiesServices from '@/app/services/companies.services';
import spacesServices from '@/app/services/spaces.services';
import { useSpaceStore } from '@/app/composables';

export default defineComponent({
  name: 'ModalLinkCompany',
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const { loadSpaces } = useSpaceStore();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const isLoadingCompanies = ref<boolean>(true);
    const currentSpace = ref<SpaceModel | null>(null);

    const linkCompanyForm = ref<RefFormModel | null>(null);

    const searchCompanies = ref<string | null>(null);
    const companiesItems = ref<SelectModel<string>[]>([]);

    const companyName = ref<string | null>(null);
    const companyId = ref<string | null>(null);

    watch(searchCompanies, (val: string | null) => {
      listCompanies(val || '').then();
    });

    const onChangeCompany = (tempData: SelectModel<string> | null) => {
      if (!tempData) {
        companyId.value = null;
        companyName.value = null;
        return;
      }
      companyId.value = tempData.value;
      companyName.value = tempData.text;
    };

    const listCompanies = async (query = '') => {
      try {
        isLoadingCompanies.value = true;
        const params = new CompaniesPagingParams(1, 5);
        params.query = query;
        const response = await companiesServices.list(params);
        companiesItems.value = response.data.map((x) => ({
          text: x.name,
          value: x._id,
        }));
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoadingCompanies.value = false;
      }
    };

    const linkCompany = async () => {
      try {
        if (!currentSpace.value) return;
        if (!companyId.value) return;
        const isFormValid = linkCompanyForm.value!.validate();
        if (!isFormValid) return;
        isLoading.value = true;
        await spacesServices.linkCompany(currentSpace.value._id, { companyId: companyId.value });
        $toast.success('La compañia se vinculo exitosamente');
        loadSpaces().then();
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    const openDialog = (space: SpaceModel) => {
      currentSpace.value = space;
      if (space.company) {
        companyName.value = space.company.name;
      }
      listCompanies().then();
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      if (!linkCompanyForm.value) return;
      isDialogVisible.value = false;
      linkCompanyForm.value.resetValidation();
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      isLoadingCompanies,
      searchCompanies,
      companiesItems,
      companyName,

      linkCompany,
      openDialog,
      closeDialog,
      onChangeCompany,

      validators: {
        required,
      },

      linkCompanyForm,
    };
  },
});
