import request from '@/app/api/api';
import { SettingModel, SettingValueModel } from '@/app/models/setting';
import SettingType from '@/app/types/setting-type';

const settingsServices = {
  add: (data: any): Promise<void> => request.post<void>('/settingValue', data),
  delete: (settingId: string): Promise<void> => request.delete<void>(`/settingValue/${settingId}`),
  edit: (settingId: string, data: any): Promise<void> =>
    request.put<void>(`/settingValue/${settingId}`, data),
  getSettingsValue: (spaceId: string, type?: SettingType): Promise<SettingValueModel[]> =>
    request.get<SettingValueModel[]>(`/settingValue`, { spaceId, type }),
  editSetting: (settingId: string, data: any): Promise<void> =>
    request.put<void>(`/setting/${settingId}`, data),
  getSettings: (spaceId: string): Promise<SettingModel> =>
    request.get<SettingModel>(`/setting`, { spaceId }),
};
export default settingsServices;
