import { defineComponent, inject, ref } from '@vue/composition-api';
import SettingType from '@/app/types/setting-type';
import settingsServices from '@/app/services/settings.services';
import useToastification from '@/app/common/hooks/useToastification';
import useStore from '@/app/common/hooks/useStore';

export default defineComponent({
  name: 'ModalSettingConfirmation',

  setup(_, ctx) {
    const $toast = useToastification();
    const store = useStore();

    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const isDelete = ref<boolean>(true);
    const isEnable = ref<boolean>(false);
    const typeName = ref<string | null>(null);
    const settingId = ref<string | null>(null);

    const handleModalError = (err: any) =>
      store.dispatch('modalError/handleModalError', { response: err });

    const handleCurrentSetting =
      inject<(type: string, val: boolean) => void>('handleCurrentSetting');

    const openDialog = (
      type: SettingType,
      deleteVal: boolean,
      enableVal: boolean,
      settingIdVal: string
    ): void => {
      typeName.value = type;
      isDelete.value = deleteVal;
      settingId.value = settingIdVal;
      isEnable.value = enableVal;
      isDialogVisible.value = true;
    };

    const closeDialog = (): void => {
      isDialogVisible.value = false;
      settingId.value = null;
      typeName.value = null;
    };
    const cancelSubmit = (): void => {
      if (settingId.value) ctx.emit('cancel-submit', !isEnable.value);
      closeDialog();
    };

    const handleFormSubmit = async (): Promise<void> => {
      try {
        isLoading.value = true;
        if (settingId.value && !isDelete.value && handleCurrentSetting && typeName.value) {
          handleCurrentSetting(typeName.value, isEnable.value);
          await settingsServices.editSetting(settingId.value, {
            value: isEnable.value,
            type: typeName.value,
          });
        }
        if (settingId.value && isDelete.value) {
          await settingsServices.delete(settingId.value);
          ctx.emit('successfully', typeName.value);
        }
        isLoading.value = false;
        closeDialog();
      } catch (err) {
        isLoading.value = false;
        if (err.response?.status === 400) {
          handleModalError(err.response);
          return;
        }
        $toast.error('Ups Ocurrió un error, intente nuevamente');
      }
    };

    return {
      isDialogVisible,
      isLoading,
      isDelete,
      isEnable,
      typeName,

      openDialog,
      closeDialog,
      cancelSubmit,
      handleFormSubmit,
    };
  },
});
