



















































































































































import { defineComponent, ref } from '@vue/composition-api';
import { useSpaceStore } from '@/app/composables';
import { formatDateLocal, formatScript, formatTruFalse } from '@/app/common/utils/filter';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import ModalListDevicesBySpace from '@/features/spaces/_components/modals/modal-list-devices-by-space/ModalListDevicesBySpace.vue';
import {
  AddUserToDeviceModalRef,
  LimitUsersModalRef,
  LinkCompanyModalRef,
  ListDevicesBySpaceModalRef,
  ListUsersBySpaceModalRef,
  ModalEditBillingDatePayModalRef,
  SpaceSettingsModalRef,
} from '@/app/models/modal';
import { SpaceModel } from '@/app/models/space';
import ModalLimitUsers from '@/features/spaces/_components/modals/modal-limit-users/ModalLimitUsers.vue';
import ModalListUsersBySpace from '@/features/spaces/_components/modals/modal-list-users-by-space/ModalListUsersBySpace.vue';
import ModalAddUserToDevice from '@/features/spaces/_components/modals/modal-add-user-to-device/ModalAddUserToDevice.vue';
import ModalSpaceSettings from '@/features/spaces/_components/modals/modal-space-settings/ModalSpaceSettings.vue';
import ModalLinkCompany from '@/features/spaces/_components/modals/modal-link-company/ModalLinkCompany.vue';
import ModalEditPayBillingDate from '@/features/spaces/_components/modals/modal-edit-pay-billing-date/ModalEditPayBillingDate.vue';
import { SHOW_CONFIG_PAY } from '@/app/common/constants/app-constants';
export default defineComponent({
  name: 'SpacesTable',
  components: {
    ModalLinkCompany,
    ModalSpaceSettings,
    ModalAddUserToDevice,
    ModalListUsersBySpace,
    ModalLimitUsers,
    ModalListDevicesBySpace,
    ModalEditPayBillingDate,
    CustomMessage,
  },
  setup() {
    const { spaces, searchMode, isLoading, searchEnd } = useSpaceStore();

    const modalListDevicesBySpace = ref<ListDevicesBySpaceModalRef | null>(null);
    const modalEditBillingDatePay = ref<ModalEditBillingDatePayModalRef | null>(null);
    const modalListUsersBySpace = ref<ListUsersBySpaceModalRef | null>(null);
    const modalLimitUsers = ref<LimitUsersModalRef | null>(null);
    const modalAddUserToDevice = ref<AddUserToDeviceModalRef | null>(null);
    const modalSpaceSettings = ref<SpaceSettingsModalRef | null>(null);
    const modalLinkCompany = ref<LinkCompanyModalRef | null>(null);
    const isShowEditPayBilling = SHOW_CONFIG_PAY === 'true';

    const openModalListDevicesBySpace = (space: SpaceModel) => {
      if (!modalListDevicesBySpace.value) return;
      modalListDevicesBySpace.value.openDialog(space);
    };

    const openModalEditBillingDatePay = (space: SpaceModel) => {
      if (!modalEditBillingDatePay.value) return;
      modalEditBillingDatePay.value.openDialog(space);
    };

    const openModalListUsersBySpace = (space: SpaceModel) => {
      if (!modalListUsersBySpace.value) return;
      modalListUsersBySpace.value.openDialog(space);
    };

    const openModalLimitUsers = (space: SpaceModel) => {
      if (!modalLimitUsers.value) return;
      modalLimitUsers.value.openDialog(space);
    };

    const openModalAddUserToDevice = (space: SpaceModel) => {
      if (!modalAddUserToDevice.value) return;
      modalAddUserToDevice.value.openDialog(space);
    };

    const openModalSpaceSettings = (space: SpaceModel) => {
      if (!modalSpaceSettings.value) return;
      modalSpaceSettings.value.openDialog(space);
    };

    const openModalLinkCompany = (space: SpaceModel) => {
      if (!modalLinkCompany.value) return;
      modalLinkCompany.value.openDialog(space);
    };

    return {
      spaces,
      searchMode,
      isLoading,

      searchEnd,
      openModalEditBillingDatePay,
      openModalListDevicesBySpace,
      openModalListUsersBySpace,
      openModalLimitUsers,
      openModalAddUserToDevice,
      openModalSpaceSettings,
      openModalLinkCompany,

      filters: { formatDateLocal, formatScript, formatTruFalse },

      modalListDevicesBySpace,
      modalEditBillingDatePay,
      modalListUsersBySpace,
      modalLimitUsers,
      modalAddUserToDevice,
      modalSpaceSettings,
      modalLinkCompany,
      isShowEditPayBilling,
    };
  },
});
