




















































import { defineComponent, reactive, ref } from '@vue/composition-api';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import useToastification from '@/app/common/hooks/useToastification';
import { RefFormModel } from '@/app/models/reference';
import countsServices from '@/app/services/counts.services';
import { ErrorModel } from '@/app/models/error';
import { numbersValidator, required } from '@/app/common/validator';
import { validNumbers } from '@/app/common/utils/check-validators';
import { SpaceModel } from '@/app/models/space';
import spacesServices from '@/app/services/spaces.services';

export default defineComponent({
  name: 'ModalLimitUsers',
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const isLoadingCurrentUsers = ref<boolean>(true);

    const form = reactive<{
      spaceId: string | null;
      currentUsers: number | null;
      maxUsersLimit: number | null;
    }>({
      spaceId: null,
      currentUsers: null,
      maxUsersLimit: null,
    });

    const limitUsersForm = ref<RefFormModel | null>(null);

    const getLimitUser = async (spaceId: string) => {
      try {
        isLoadingCurrentUsers.value = true;
        const response = await countsServices.contUser(spaceId);
        form.currentUsers = response.limit;
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoadingCurrentUsers.value = false;
      }
    };

    const updateLimit = async () => {
      try {
        const isFormValid = limitUsersForm.value!.validate();
        if (!isFormValid) return;
        isLoading.value = true;
        await spacesServices.maxUserLimit(form);
        $toast.success('El maximo de Usuarios se actualizo correctamente');
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    const openDialog = (space: SpaceModel) => {
      getLimitUser(space._id).then();
      isDialogVisible.value = true;
      form.spaceId = space._id;
    };

    const closeDialog = () => {
      if (!limitUsersForm.value) return;
      isDialogVisible.value = false;
      form.currentUsers = null;
      form.maxUsersLimit = null;
      limitUsersForm.value.resetValidation();
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      loadingCurrentUsers: isLoadingCurrentUsers,
      form,

      openDialog,
      closeDialog,
      updateLimit,

      validators: {
        required,
        numbersValidator,
      },

      checkValidators: {
        validNumbers,
      },

      limitUsersForm,
    };
  },
});
