



















import { defineComponent } from '@vue/composition-api';
import { useSpaceStore } from '@/app/composables';

export default defineComponent({
  name: 'SpacesPaginate',
  setup() {
    const { pagination, isLoading, setPaginate, loadSpaces } = useSpaceStore();
    const handlePagination = (val: number): void => {
      setPaginate(val);
      loadSpaces().then();
    };
    return { pagination, isLoading, handlePagination };
  },
});
