

























import { defineComponent, onMounted } from '@vue/composition-api';
import CustomMainTable from '@/app/common/components/custom-main-table/CustomMainTable.vue';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import { useSpaceStore } from '@/app/composables';
import SpacesTable from '@/features/spaces/spaces-table/SpacesTable.vue';

export default defineComponent({
  name: 'SpacesList',
  components: { SpacesTable, CustomMessage, CustomProgressCircular, CustomMainTable },
  setup() {
    const { isLoading, error, loadSpaces, searchEnd } = useSpaceStore();
    onMounted(() => {
      loadSpaces();
    });
    return {
      isLoading,
      error,
      searchEnd,
    };
  },
});
