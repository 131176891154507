

















import { defineComponent, ref } from '@vue/composition-api';
import { UserModel } from '@/app/models/user';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import { ErrorModel } from '@/app/models/error';
import spacesServices from '@/app/services/spaces.services';
import { SpaceModel } from '@/app/models/space';
import useToastification from '@/app/common/hooks/useToastification';

export default defineComponent({
  name: 'ModalConfirmUnlinkUser',
  emits: ['successful-unlinking'],
  setup(_, ctx) {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const currentUser = ref<UserModel | null>(null);
    const currentSpace = ref<SpaceModel | null>(null);

    const openDialog = (user: UserModel, space: SpaceModel) => {
      currentUser.value = user;
      currentSpace.value = space;
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      isDialogVisible.value = false;
      currentUser.value = null;
      currentSpace.value = null;
    };

    const unlinkUser = async () => {
      try {
        if (!currentUser.value) return;
        if (!currentSpace.value) return;
        isLoading.value = true;
        await spacesServices.unlinkUser(currentUser.value._id, currentSpace.value._id);
        $toast.success('El usuario se desvinculo correctamente');
        ctx.emit('successful-unlinking', currentSpace.value._id);
        closeDialog();
      } catch (err) {
        closeDialog();
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    return { isDialogVisible, currentUser, isLoading, openDialog, closeDialog, unlinkUser };
  },
});
