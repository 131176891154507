














































import { defineComponent, ref } from '@vue/composition-api';
import { SpaceModel } from '@/app/models/space';

export default defineComponent({
  name: 'ModalListDevicesBySpace',
  setup() {
    const isDialogVisible = ref<boolean>(false);
    const currentSpace = ref<SpaceModel | null>(null);

    const openDialog = (space: SpaceModel) => {
      currentSpace.value = space;
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      isDialogVisible.value = false;
    };

    return { isDialogVisible, currentSpace, openDialog, closeDialog };
  },
});
