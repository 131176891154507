








































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import useToastification from '@/app/common/hooks/useToastification';
import { RefFormModel } from '@/app/models/reference';
import { ErrorModel } from '@/app/models/error';
import { numbersValidator, required } from '@/app/common/validator';
import { convertTrueFalse, validNumbers } from '@/app/common/utils/check-validators';
import spacesServices from '@/app/services/spaces.services';
import { formatScript } from '@/app/common/utils/filter';
import { useSpaceStore } from '@/app/composables';
export default defineComponent({
  name: 'ModalEditPayBillingDate',
  setup() {
    const { loadSpaces } = useSpaceStore();
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const isLoadingCurrentUsers = ref<boolean>(true);
    const menuDate = ref<boolean>(false);
    const spaceId = ref<string | null>(null);
    const toPay = ref<boolean | string | null>(null);

    const form = reactive<{
      toPay: string | boolean | null;
      billingDate: string | null;
    }>({
      toPay: '',
      billingDate: null,
    });

    const editDateForm = ref<RefFormModel | null>(null);

    const updatePayBillingDate = async () => {
      try {
        const isFormValid = editDateForm.value!.validate();
        if (!isFormValid) return;
        isLoading.value = true;
        form.toPay = convertTrueFalse(toPay.value);
        await spacesServices.editPayBilling(spaceId.value, form);
        $toast.success('Se ha editado correctamente');
        loadSpaces().then();
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    const openDialog = (space: any) => {
      toPay.value = space.toPay ? 'true' : 'false';
      const dateBilling = formatScript(space.billingDate, true);
      form.billingDate = dateBilling.split('T')[0];
      isDialogVisible.value = true;
      spaceId.value = space._id;
    };

    const closeDialog = () => {
      if (!editDateForm.value) return;
      isDialogVisible.value = false;
      toPay.value = null;
      form.billingDate = null;
      spaceId.value = null;
      editDateForm.value.resetValidation();
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      loadingCurrentUsers: isLoadingCurrentUsers,
      form,
      menuDate,
      toPay,

      openDialog,
      closeDialog,
      updatePayBillingDate,

      validators: {
        required,
        numbersValidator,
      },

      checkValidators: {
        validNumbers,
      },

      editDateForm,
    };
  },
});
