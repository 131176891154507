












































































import { defineComponent, ref } from '@vue/composition-api';
import { SpaceModel } from '@/app/models/space';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import { ErrorModel } from '@/app/models/error';
import spacesServices from '@/app/services/spaces.services';
import { UserModel } from '@/app/models/user';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import { GLOBAL_NAME_ROLE } from '@/app/common/helpers/roles';
import ModalConfirmUnlinkUser from '@/features/spaces/_components/modals/modal-confirm-unlink-user/ModalConfirmUnlinkUser.vue';
import { ConfirmUnlinkUserModalRef } from '@/app/models/modal';

export default defineComponent({
  name: 'ModalListUsersBySpace',
  components: { ModalConfirmUnlinkUser, CustomProgressCircular },
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const globalRoleName = GLOBAL_NAME_ROLE;
    const isDialogVisible = ref<boolean>(false);
    const loadingUsers = ref<boolean>(false);
    const currentSpace = ref<SpaceModel | null>(null);
    const users = ref<UserModel[]>([]);

    const modalConfirmUnlinkUser = ref<ConfirmUnlinkUserModalRef | null>(null);

    const openModalConfirmUnlinkUser = (user: UserModel) => {
      if (!modalConfirmUnlinkUser.value) return;
      if (!currentSpace.value) return;

      modalConfirmUnlinkUser.value.openDialog(user, currentSpace.value);
    };

    const openDialog = (space: SpaceModel) => {
      currentSpace.value = space;
      listUsers(space._id).then();
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      isDialogVisible.value = false;
    };

    const listUsers = async (spaceId: string) => {
      try {
        loadingUsers.value = true;
        const response = await spacesServices.listUser(spaceId);
        users.value = response;
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        loadingUsers.value = false;
      }
    };

    return {
      isDialogVisible,
      currentSpace,
      loadingUsers,
      users,
      globalRoleName,

      openDialog,
      closeDialog,
      openModalConfirmUnlinkUser,
      listUsers,

      modalConfirmUnlinkUser,
    };
  },
});
