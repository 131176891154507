








import { defineComponent, onUnmounted } from '@vue/composition-api';
import SpacesHeader from '@/features/spaces/spaces-header/SpacesHeader.vue';
import SpacesList from '@/features/spaces/spaces-list/SpacesList.vue';
import SpacesPaginate from '@/features/spaces/spaces-paginate/SpacesPaginate.vue';
import { useSpaceStore } from '@/app/composables';

export default defineComponent({
  name: 'SpacesView',
  components: { SpacesPaginate, SpacesList, SpacesHeader },
  setup() {
    const { resetStatus } = useSpaceStore();

    onUnmounted(() => {
      resetStatus();
    });

    return {};
  },
});
