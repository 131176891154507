
























































import { defineComponent, reactive, ref } from '@vue/composition-api';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import useToastification from '@/app/common/hooks/useToastification';
import { emailValidator, required } from '@/app/common/validator';
import { SelectModel } from '@/app/models/select-model';
import { NAME_SUB_USERS_TYPE, NAME_USERS_TYPE } from '@/app/common/constants/types-users';
import { ErrorModel } from '@/app/models/error';
import { SpaceModel } from '@/app/models/space';
import spacesServices from '@/app/services/spaces.services';
import { RefFormModel } from '@/app/models/reference';

export default defineComponent({
  name: 'ModalAddUserToDevice',
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const usersItems = ref<SelectModel<string>[]>([]);
    const subUsersItems = ref<SelectModel<string>[]>([]);
    const currentSpace = ref<SpaceModel | null>(null);

    const addUserForm = ref<RefFormModel | null>(null);

    const form = reactive<{
      user: string | null;
      email: string;
      subUser: string | null;
    }>({
      user: null,
      email: '',
      subUser: null,
    });

    usersItems.value = Object.entries(NAME_USERS_TYPE).map((x) => ({
      text: x[1],
      value: x[0],
    }));

    subUsersItems.value = Object.entries(NAME_SUB_USERS_TYPE).map((x) => ({
      text: x[1],
      value: x[0],
    }));

    const addUser = async () => {
      try {
        const isFormValid = addUserForm.value!.validate();
        if (!isFormValid) return;
        if (!currentSpace.value) return;
        isLoading.value = true;
        const spaceId = currentSpace.value._id;
        if (form.user === 'USER' && form.subUser) {
          await spacesServices.createUser(spaceId, {
            role: form.subUser,
            email: form.email,
          });
        }
        if (form.user === 'MONITOR') {
          await spacesServices.createMonitor(spaceId, { email: form.email });
        }
        $toast.success('Usuario agregado correctamente');
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    const openDialog = (space: SpaceModel) => {
      currentSpace.value = space;
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      if (!addUserForm.value) return;
      isDialogVisible.value = false;
      form.user = null;
      form.subUser = null;
      form.email = '';
      addUserForm.value.resetValidation();
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      form,
      usersItems,
      subUsersItems,

      openDialog,
      closeDialog,
      addUser,

      validators: {
        required,
        emailValidator,
      },
      addUserForm,
    };
  },
});
