import { defineComponent, provide, ref } from '@vue/composition-api';
import settingsServices from '@/app/services/settings.services';
import SettingType from '@/app/types/setting-type';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import ModalSettingForm from '@/features/spaces/_components/modals/modal-setting-form/ModalSettingForm.vue';
import { SettingModel, SettingValueModel } from '@/app/models/setting';
import ModalSettingConfirmation from '@/features/spaces/_components/modals/modal-setting-confirmation/ModalSettingConfirmation.vue';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import { ErrorModel } from '@/app/models/error';

export default defineComponent({
  name: 'ModalSpaceSettings',
  components: { CustomProgressCircular, CustomMessage, ModalSettingForm, ModalSettingConfirmation },
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const tabItems = ref(['email', 'sms', 'call']);
    const isLoading = ref<boolean>(false);
    const isDialogVisible = ref<boolean>(false);
    const tab = ref<string>('email');
    const checkbox = ref<boolean>(false);
    const currentSpace = ref<any>(null);
    const currentSetting = ref<SettingModel | null>(null);
    const isComplete = ref<boolean>(false);
    const settingsValues = ref<SettingValueModel[]>([]);

    const modalSettingForm = ref<any>(null);
    const modalSettingConfirmation = ref<any>(null);

    const closeDialog = (): void => {
      isDialogVisible.value = false;
      tab.value = 'email';
      currentSpace.value = null;
      currentSetting.value = null;
    };

    const handleTab = (value: SettingType): void => {
      isComplete.value = false;
      checkbox.value = false;
      loadSettings(value).then();
    };

    const handleCheckbox = (val: boolean): void => {
      if (!currentSetting.value) return;
      if (!tab.value) return;
      openModalSettingConfirmation(tab.value, false, val, currentSetting.value._id);
    };

    const openDialog = (space: any): void => {
      isDialogVisible.value = true;
      currentSpace.value = space;
      loadSettings('email').then();
    };

    const loadSettings = async (type: SettingType): Promise<void> => {
      try {
        if (!currentSpace.value) return;
        isDialogVisible.value = true;
        isLoading.value = true;
        if (!currentSetting.value) {
          currentSetting.value = await settingsServices.getSettings(currentSpace.value._id);
        }
        checkbox.value =
          type === 'email'
            ? currentSetting.value.emailSend
            : type === 'sms'
            ? currentSetting.value.smsSend
            : currentSetting.value.callSend;

        settingsValues.value = await settingsServices.getSettingsValue(
          currentSpace.value._id,
          type
        );
        /*isComplete.value = resSettingsValue.length === 7;*/
        isLoading.value = false;
      } catch (err) {
        isLoading.value = false;
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      }
    };

    const handleCurrentSetting = (type: SettingType, val: boolean) => {
      if (!currentSetting.value) return;
      if (type === 'email') {
        currentSetting.value.emailSend = val;
      }
      if (type === 'sms') {
        currentSetting.value.smsSend = val;
      }
      if (type === 'call') {
        currentSetting.value.callSend = val;
      }
    };

    provide('handleCurrentSetting', handleCurrentSetting);

    const openModalSettingForm = (type: string, setting?: SettingValueModel) => {
      if (!modalSettingForm.value) return;
      if (!currentSpace.value) return;
      modalSettingForm.value.openDialog(type, currentSpace.value._id, setting);
    };

    const openModalSettingConfirmation = (
      type: string,
      isDelete: boolean,
      isEnable: boolean,
      settingId: string
    ) => {
      if (!modalSettingConfirmation.value) return;
      modalSettingConfirmation.value.openDialog(type, isDelete, isEnable, settingId);
    };

    const onCancel = (val: boolean) => {
      checkbox.value = val;
    };

    return {
      checkbox,
      isLoading,
      isDialogVisible,
      tab,
      tabItems,
      settingsValues,
      isComplete,

      closeDialog,
      openDialog,
      handleTab,
      openModalSettingForm,
      openModalSettingConfirmation,
      loadSettings,
      handleCheckbox,
      onCancel,
      handleCurrentSetting,

      modalSettingForm,
      modalSettingConfirmation,
    };
  },
});
