export const NAME_USERS_TYPE = {
  ['MONITOR']: 'Monitor',
  ['USER']: 'Usuario',
};

export const NAME_SUB_USERS_TYPE = {
  ['SUPERVISOR']: 'Supervisor',
  ['USER']: 'Usuario',
  ['ADMIN']: 'Administrador',
};
