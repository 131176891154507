import { defineComponent, ref } from '@vue/composition-api';
import { required, emailValidator, phoneNumberValidator } from '@/app/common/validator';
import SettingType from '@/app/types/setting-type';
import { RefFormModel } from '@/app/models/reference';
import settingsServices from '@/app/services/settings.services';
import { SettingValueModel } from '@/app/models/setting';
import { ErrorModel } from '@/app/models/error';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';

export default defineComponent({
  name: 'ModalSettingForm',
  setup(_, ctx) {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const settingForm = ref<RefFormModel | null>(null);
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const editMode = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const typeName = ref<string | null>(null);
    const formValue = ref<string>('');
    const spaceId = ref<string | null>(null);
    const currentSettingValue = ref<SettingValueModel | null>(null);

    const openDialog = (type: SettingType, id: string, setting?: SettingValueModel): void => {
      if (setting) {
        editMode.value = true;
        formValue.value = setting.value;
        currentSettingValue.value = setting;
      }
      spaceId.value = id;
      typeName.value = type;
      isDialogVisible.value = true;
    };

    const closeDialog = (): void => {
      isDialogVisible.value = false;
      editMode.value = false;
      formValue.value = '';
      typeName.value = null;
      settingForm.value!.resetValidation();
    };

    const handleFormSubmit = async (): Promise<void> => {
      try {
        if (!settingForm.value) return;
        if (!settingForm.value.validate()) return;
        isLoading.value = true;
        const form = {
          spaceId: spaceId.value,
          type: typeName.value,
          value: formValue.value,
        };
        if (!editMode.value) {
          await settingsServices.add(form);
        }

        if (editMode.value && currentSettingValue.value) {
          await settingsServices.edit(currentSettingValue.value._id, form);
        }

        ctx.emit('successfully', typeName.value);
        isLoading.value = false;
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
        isLoading.value = false;
      }
    };

    return {
      isDialogVisible,
      isLoading,
      editMode,
      valid,
      typeName,
      formValue,

      openDialog,
      closeDialog,
      handleFormSubmit,

      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
      },

      settingForm,
    };
  },
});
