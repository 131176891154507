



































import { defineComponent, ref } from '@vue/composition-api';
import SearchSpace from '@/features/spaces/_components/search-space/SearchSpace.vue';
import spacesServices from '@/app/services/spaces.services';
import useToastification from '@/app/common/hooks/useToastification';
import { ErrorModel } from '@/app/models/error';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';

export default defineComponent({
  name: 'SpacesHeader',
  components: { SearchSpace },
  setup() {
    const $toast = useToastification();
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const isLoading = ref<boolean>(false);
    const dowloandReport = async () => {
      try {
        isLoading.value = true;
        const report: any = await spacesServices.dowloandReport();
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reporte_inmuebles.xlsx');
        document.body.appendChild(link);
        link.click();
        $toast.success('Se ha descargado correctamente');
      } catch (error) {
        await handleApiGatewayInterceptor(error.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      dowloandReport,
      isLoading,
    };
  },
});
